import React, { Fragment, PureComponent } from 'react';
import { List, Map } from 'immutable';
import ReactGA from 'react-ga';
import currencyFormatter from 'currency-formatter';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import ItemRow from './components/ItemRow.jsx';
import LandingStartup from './LandingStartup.jpg';
import './App.css';
import Axios from 'axios';

ReactGA.initialize('UA-173970815-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function generateItem(name, amount, numPeriods, count, remarks = null, formula = null) {
  return new Map({
    name,
    amount,
    numPeriods,
    count,
    remarks,
  });
}

const defaultData = new List([
  new Map({
    id: 'basic',
    name: '基本費用',
    items: new List([
      generateItem('商業登記及公司註冊', 2250, 1, 1, '必需'),
      generateItem('虛擬辦公室服務', 150, 12, 1, '建議由 VO 開戶'),
      generateItem('商務中心 / 共享空間', null, 0, 0, '視乎需要'),
      generateItem('每年審計費用', null, 1, 0, '有限公司必需'),
      generateItem('銀行公司查冊 / 開戶費', 200, 1, 1, '非常建議開戶'),
      generateItem('銀行帳號月費', 150, 12, 1, '如達到最低存款額可以豁免'),
      generateItem('雜項', null, 1, 1),
    ])
  }),
  new Map({
    id: 'equipment',
    name: '基本設備及軟件',
    items: new List([
      generateItem('網域 www', 250, 1, 1, '必需'),
      generateItem('網頁設計/平台服務', 150, 12, 1),
      generateItem('Microsoft Office 365 / Google G Suite', 97, 12, 1, '必需'),
      generateItem('Adobe Creactive Cloud', 408, 12, 1),
      generateItem('電腦及配件', 12000, 1, 0),
      generateItem('相機及配件', null, 1, 0),
      generateItem('收音咪/燈光等配件', 600, 1, 0),
      generateItem('所需素材', null, 12, 0),
      generateItem('雜項', null, 1, 1),
    ])
  }),
  new Map({
    id: 'branding',
    name: '設計及推廣',
    items: new List([
      generateItem('Logo / Branding', null, 1, 1, '必需'),
      generateItem('卡片', 45, 1, 1, '100 張'),
      generateItem('Facebook / Google Ads', null, 1, 1),
      generateItem('雜項', null, 1, 1),
    ])
  }),
  new Map({
    id: 'salary',
    name: '薪金及福利',
    items: new List([
      generateItem('月薪', null, 12, 1, ''),
      generateItem('MPF', null, 12, 1, ''),
      generateItem('勞工保險', null, 1, 1, ''),
    ])
  }),
  new Map({
    id: 'service',
    name: '服務或產品成本',
    items: new List([
      generateItem('成本及存倉', null, 1, 0),
      generateItem('交易費', null, 1, 0, 'Payment Gateway: 3.4-3.7%'),
      generateItem('運費', null, 1, 0),
    ])
  })
]);

class App extends PureComponent {
  constructor() {
    super();
    this.state = {
      step: 1,
      name: '',
      email: '',
      data: defaultData,
      errors: new Map(),
      showAlert: false,
    };
  }

  calculateTotal = () => {
    const { data } = this.state;
    return data.reduce(
      (total, section) => (
        total + section.get('items').reduce(
          (t, item) => t + item.get('amount') * item.get('numPeriods') * item.get('count'), 0
        )
      ),
      0
    );
  }

  onChangeHandler = (sectionId, itemId, field, value) => {
    const { data } = this.state;
    const section = data.get(sectionId);
    let items = section.get('items');
    let item = items
      .get(itemId)
      .set(field, value)
    ;
    items = items.set(itemId, item);

    if (item.get('name') === '月薪' && field === 'amount') {
      let mpf = 0;
      if (value > 30000) {
        mpf = 1500;
      } else if (value >= 7100) {
        mpf = value * 0.05;
      }
      item = items.get(1).set('amount', mpf);
      items = items.set(1, item);
    }

    this.setState({
      data: data.set(
        sectionId,
        section.set('items', items)
      )
    });
  }

  onChangeNameHandler = (e) => {
    this.setState({
      name: e.target.value,
    });
  }

  onChangeEmailHandler = (e) => {
    this.setState({
      email: e.target.value,
    });
  }

  onClickNextStepHandler = (e) => {
    e.preventDefault();
    // Validate
    const { name, email } = this.state;
    let errors = new Map();
    if (name.length === 0) {
      errors = errors.set('name', ['請填寫名字']);
    } else if (name.length > 255) {
      errors = errors.set('name', ['名字太長，長度不可多於 255 個字']);
    }

    if (email.length === 0) {
      errors = errors.set('email', ['請填寫電郵']);
    } else if (email.length > 255) {
      errors = errors.set('email', ['電郵太長，長度不可多於 255 個字']);
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      errors = errors.set('email', ['請填寫有效的電郵地址']);
    }

    if (errors.size === 0) {
      this.setState({
        step: 2,
      });
    } else {
      this.setState({
        errors,
      });
    }
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    const { name, email } = this.state;
    const data = {
      name,
      email,
      data: this.state.data.toJS(),
    };
    this.setState({
      errors: new Map(),
    });
    Axios
      .post(process.env.REACT_APP_API_ENDPOINT, data)
      .then((res) => {
        const data = res.data;
        if (data.errors) {
          this.setState({
            errors: new Map(data.errors),
            showAlert: 'danger',
          });
        } else {
          this.setState({
            showAlert: 'success',
          });
        }
      })
    ;
  }

  onResetHandler = (e) => {
    e.preventDefault();
    this.setState({
      name: '',
      email: '',
      data: defaultData,
    });
  }

  onBackHandler = (e) => {
    this.setState({
      step: 1,
    });
  }

  onConfirmAlertHandler = () => {
    this.setState({
      showAlert: false,
    });
  }

  onCancelAlertHandler = () => {
    this.setState({
      showAlert: false,
    });
  }

  render() {
    const { step, name, email, data, errors, showAlert } = this.state;
    const total = this.calculateTotal();
    return (
      <Fragment>
        <div className="App">
          {
            step === 1 && (
              <section className="header header-6" style={{ backgroundImage: `url(${LandingStartup})` }}>
                <div className="container">
                  <div className="row">
                    <div className="img-landing-startup">
                      <img src={ LandingStartup } alt="Landing" />
                    </div>
                    <div className="col-md-5 col-sm-8 col-md-push-7 col-sm-push-2 form-contact">
                      <h3>創業資金計算參考 (@unlockio)</h3>
                      <p>適合有興趣開始網絡創業的您，以下成本及支出項目以知識或創意產品及服務為主，可以按你的業務所需來增減。</p>
                      <form onSubmit={ this.onClickNextStepHandler } className="form-email">
                        <h6>請輸入名字及電郵，完成計算後，系統會把你的創業資金預算電郵給你。</h6>
                        <input
                          type="text"
                          className={ classnames('signup-name-field', errors.get('name') && 'field-error') }
                          id="name"
                          value={ name }
                          onChange={ this.onChangeNameHandler }
                          placeholder="名字"
                        />
                        {
                          errors.get('name') && (
                            <div className="invalid-feedback">
                              {
                                errors.get('name').map((error, i) => (
                                  <p key={ `name-errors-${i}` }>{ error }</p>
                                ))
                              }
                            </div>
                          )
                        }

                        <input
                          type="email"
                          className={ classnames('signup-email-field', errors.get('email') && 'field-error') }
                          id="email"
                          value={ email }
                          onChange={ this.onChangeEmailHandler }
                          placeholder="電郵"
                        />
                        {
                          errors.get('email') && (
                            <div className="invalid-feedback">
                              {
                                errors.get('email').map((error, i) => (
                                  <p key={ `email-errors-${i}` }>{ error }</p>
                                ))
                              }
                            </div>
                          )
                        }

                        <input type="submit" value="開始我的創業預算" />
                        <p className="sub">
                          如果您繼續，即表示您同意我們的<a href="privacy.html" target="_blank">隱私權政策</a>。
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            )
          }

          {
            step === 2 && (
              <form className="form-table" onSubmit={ this.onSubmitHandler }>
                <table className="table table-borderless table-app">
                  <thead>
                    <tr>
                      <th id="table-item-name">&nbsp;</th>
                      <th id="table-item-amount">每月</th>
                      <th id="table-item-numPeriods">期數</th>
                      <th id="table-item-count">數目</th>
                      <th id="table-item-subtotal">項目小計</th>
                      <th id="table-item-empty">&nbsp;</th>
                      <th id="table-item-remarks">備註</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map((section, i) => (
                        <Fragment key={ `app-section-${i}` }>
                          <tr>
                            <th colSpan={ 7 } className={ `app-section-heading-${section.get('id')}` }>{ section.get('name') }</th>
                          </tr>
                          {
                            section.get('items').map((item, j) => (
                              <ItemRow
                                key={ `app-section-${i}-${j}` }
                                sectionId={ i }
                                itemId={ j }
                                item={ item }
                                onChange={ this.onChangeHandler }
                              />
                            ))
                          }
                          <tr>
                            <td colSpan={ 7 }>&nbsp;</td>
                          </tr>
                        </Fragment>
                      ))
                    }
                  </tbody>
                  <tfoot>
                    <tr id="table-tr-total">
                      <td colSpan={ 4 }>創業資金 (首年)</td>
                      <td>{ currencyFormatter.format(total, { locale: 'en-US', symbol: 'HK$ ' }) }</td>
                      <td colSpan={ 2 }>&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan={ 4 }>創業成本 (每月)</td>
                      <td>{ currencyFormatter.format(total / 12, { locale: 'en-US', symbol: 'HK$ ' }) }</td>
                      <td colSpan={ 2 }>&nbsp;</td>
                    </tr>
                  </tfoot>
                </table>

                <div className="row">
                  <div className="col-sm-2" />
                  <div className="col-xs-6 col-sm-4">
                    <input type="submit" className="btn btn-primary btn-block" value="提交" />
                  </div>
                  <div className="col-xs-6 col-sm-4">
                    <button type="button" className="btn btn-filled-alt btn-block" onClick={ this.onBackHandler }>返回</button>
                  </div>
                </div>
              </form>
            )
          }
        </div>

        {
          showAlert && (
            <SweetAlert
              title={ showAlert === 'success' ? '成功提交' : '發生錯誤' }
              success={ showAlert === 'success' }
              danger={ showAlert === 'danger' }
              confirmBtnText="確定"
              confirmBtnBsStyle={ showAlert }
              onConfirm={ this.onConfirmAlertHandler }
              onCancel={ this.onCancelAlertHandler }
            >
              { showAlert === 'success' ? '資料已成功提交，並已發送至閣下的電郵。' : '發生錯誤！請檢查表格所填的資料後重試。' }
            </SweetAlert>
          )
        }
      </Fragment>
    );
  }
}

export default App;
