import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';

class ItemRow extends PureComponent {
  constructor() {
    super();
    this.onChangeAmountHandler = this.onChangeHandler.bind(this, 'amount');
    this.onChangeNumPeriodsHandler = this.onChangeHandler.bind(this, 'numPeriods');
    this.onChangeCountHandler = this.onChangeHandler.bind(this, 'count');
  }

  onChangeHandler = (field, e) => {
    const { sectionId, itemId, onChange } = this.props;
    const value = parseFloat(e.target.value) || 0;
    onChange(sectionId, itemId, field, value);
  }

  render() {
    const { item } = this.props;
    return (
      <tr>
        <th>{ item.get('name') }</th>
        <td className="td-amount">
          <span className="dollar">HK$ </span>
          <input
            type="number"
            className="form-control form-control-with-dollar"
            value={ item.get('amount') || '' }
            onChange={ this.onChangeAmountHandler }
            readOnly={ item.get('name') === 'MPF' }
          />
        </td>
        <td>
         <input
            type="number"
            className="form-control"
            value={ item.get('numPeriods') }
            onChange={ this.onChangeNumPeriodsHandler }
          />
        </td>
        <td>
         <input
            type="number"
            className="form-control"
            value={ item.get('count') }
            onChange={ this.onChangeCountHandler }
          />
        </td>
        <td>{ currencyFormatter.format(item.get('amount') * item.get('numPeriods') * item.get('count'), { locale: 'en-US', symbol: 'HK$ ' }) }</td>
        <td>&nbsp;</td>
        <td>{ item.get('remarks') }</td>
      </tr>
    );
  }
}

ItemRow.propTypes = {
  sectionId: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ItemRow;